import React from 'react';
import styled, {keyframes} from 'styled-components';
import Button from './button';
import {MdCheckCircle, MdCancel} from 'react-icons/md';
import {FadeIn} from '../animations/anim';


const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  transition-duration: 0.4s;
`;

const PriceCol = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #282828;
  padding: 24px;
  margin-right: 24px;
  min-width: 260px;
  max-width: 320px;
  min-height: 600px;
  margin-bottom: 24px;
  flex: 1;
  animation-name: '${FadeIn}';
  animation-duration: .4s;
  animation-fill-mode: forwards;
  animation-delay: ${props => `${props.animDelay}s`};
  animation-play-state: ${props => props.animState};
  opacity: 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type:none
`;

const LI = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 300;
`

const Pricing = ({prices, style, animState}) => (
  <Container style={{...style}}>
    {prices.map((p, i) => {
      return (
        <PriceCol
          key={`price_${i}`}
          animState={animState ? animState : 'running'}
          animDelay={0.12*i}>
          <h2>
            {p.title}
          </h2>
          <h3 style={{marginTop: 32}}>
            {p.price}
          </h3>
          <p style={{height: 40}}>{p.priceExtra}</p>
          <p>
            {p.text}
          </p>
          {p.features &&
          <UL>
            {p.features.map(f => {
              return (
                <LI>
                  {f.included === false &&
                  <MdCancel style={{color: '#ffc9c9', marginRight: 8}}/>
                  ||
                  <MdCheckCircle style={{color: '#C9DDFF', marginRight: 8}}/>
                  }
                  {f.component}
              </LI>
              )}
            )}
          </UL>
          }
          <div style={{flex: 1}}/>
          <Row style={{justifyContent: 'center', marginTop: 32}}>
            <Button style={{alignSelf: 'stretch', width: '100%'}}
              to={p.button.to}
              text={p.button.text}/>
          </Row>
        </PriceCol>
      );
    })}
  </Container>
);

export default Pricing;
