import styled, {keyframes} from 'styled-components';

export const FadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(.95);
  };
  100% {
    opacity: 1;
    transform: scale(1);
  };
`;
